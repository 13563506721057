import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import piexif from 'piexifjs';
import './App.css';

function App() {
  const [image, setImage] = useState(null);
  const [exifData, setExifData] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      readFile(file);
    }
  }, []);

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      setImage(data);
      try {
        const exif = piexif.load(data);
        setExifData(exif);
      } catch (error) {
        console.error("Error reading EXIF data", error);
        setExifData(null);
      }
    };
    reader.onerror = (e) => {
      console.error("Error reading file", e);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="App">
      <div className="dropzone-container">
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} />
          {image ? (
            <img src={image} alt="Uploaded" className="uploaded-image" />
          ) : (
            <p>Drag and drop an image here, or click to select a file</p>
          )}
        </div>
      </div>
      {exifData && (
        <div className="exif-data">
          <h2>EXIF Data:</h2>
          <pre>{JSON.stringify(exifData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default App;
